import React from 'react'
import _ from 'lodash'
import moment from 'moment'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import InfiniteScroll from 'react-infinite-scroller'
import Notification from './Notification'
import FeedTitle from './FeedTitle'
import Posts from './Posts'
import { Spin } from 'antd'

import {
  feedSnapshot,
  feedUpdateBottom,
  feedUpdateTop,
  flushNews,
  feedRemoveAllNew,
  feedRemoveAll,
} from '../../../redux/ducks/feeds'
import { readlistSnapshot } from '../../../redux/ducks/favourites'
import { applyFiltersUpdate } from '../../../redux/ducks/filters'
import { fetchStream } from '../../../api/streams'
import { fetchReadList } from '../../../api/readlist'
import { getFeedId, getFilterNameFromOriginal } from '../../../helpers'

import styled, { css } from 'styled-components/macro'
import { cssvar } from '../../../styles/var'

class Feed extends React.Component {

  state = {
    notificationVisible: false,
    loading: false
  }

  componentDidMount() {
    if (!this.props.isReadListPage) {
      this.loadSnapshot()
      this.pollingInterval = setInterval(() => this.readyToPoll(), 30000)
    } else {
      fetchReadList()
        .then((readlist) => {
          this.props.setReadlist(readlist)
        })
    }
  }

  componentWillUnmount() {
    if (!this.props.isCrossFeedVisible) {
      clearInterval(this.pollingInterval)
    }
  }

  componentDidUpdate(prevProps) {
    const { tag, filters = {}, removeNewFeeds, removeFeeds, original, hashtag, fulltext, isCrossFeed, isFavourite, hashtagTag, applyUpdate } = this.props

    const opts = { tag, original, hashtag, isCrossFeed, isFavourite, hashtagTag }

    const id = getFeedId(opts)
    const updateFeed = () => {
      removeNewFeeds(id)
      this.setState({ notificationVisible: false })
      removeFeeds(id)
      this.loadSnapshot()
    }

    if (tag !== prevProps.tag || prevProps.hashtagTag !== hashtagTag || prevProps.hashtag !== hashtag || prevProps.fulltext !== fulltext) {
      updateFeed()
    }
    if (filters.applyUpdate) {
      updateFeed()
      applyUpdate(false)
    }
    if (filters.fromSocials !== prevProps.filters.fromSocials) {
      if (original === 1) {
        updateFeed()
        applyUpdate(false)
      }
    }
    if (filters.aboutSocials !== prevProps.filters.aboutSocials) {
      if (original === 2) {
        updateFeed()
        applyUpdate(false)
      }
    }
    if (filters.analyticsSocials !== prevProps.filters.analyticsSocials) {
      if (original === 3) {
        updateFeed()
        applyUpdate(false)
      }
    }
  }

  _scrollWrapper
  onScrollTop = () => {
    this._scrollWrapper.scrollTop = 0;
  }
  getScrollWrapper = (elem) => {
    this._scrollWrapper = elem;
  }

  loadBottom = () => {
    const { tag, original, name, filters = {}, hashtag, isFavourite, isReadListPage, isCrossFeed, isCrossTag, isGeo, hashtagLabel, hashtagTag, feeds = {}, feedScrollUpdate, fulltext } = this.props
    const { from, to, category, type, lang } = filters
    
    this.setState({ loading: true })

    let options = {
      geo: isGeo,
      o: 1,
      d: { from, to },
      filters: {
        category,
        type,
        lang,
        social_types: filters[getFilterNameFromOriginal(original)]
      }
    }
    let id = name

    if (isReadListPage) {
    } else {
      if (isCrossFeed) {
        id = `${tag}-${hashtagTag}`
        if (isCrossTag) {
          if (fulltext) {
            options = {
              ...options,
              o: 0,
              h: false,
              f: true,
              q: hashtagTag,
              r: fulltext,
              t: 0
            }
          } else {
            options = {
              ...options,
              h: true,
              q: hashtagLabel,
              r: tag
            }
          }
        } else {
          options = {
            ...options,
            h: true,
            q: hashtagTag,
            r: tag
          }
        }
      } else if (hashtag) {
        id = hashtag
        options = {
          ...options,
          s: 0,
          h: true,
          q: hashtag
        }
      } else if (fulltext) {
        id = fulltext
        options = {
          o: 1,
          f: true,
          s: 0,
          q: fulltext
        }
      } else if (tag) {
        id = `${original}-${tag}`
        options = {
          ...options,
          s: 0,
          original,
          q: tag
        }
      } else if (isFavourite) {
        id = `favourites-${original}`
        options = {
          ...options,
          original,
          s: 0
        }
      }


      if (feeds[id] && feeds[id].length > 0) {
        const last = _.findLast(feeds[id])
        const { _source = {} } = last
        const { date } = _source
        const lastTs = date
        options = {
          ...options,
          t: lastTs
        }

        fetchStream(options, isFavourite, isCrossFeed)
          .then((feed) => {
            feedScrollUpdate(feed, id)
            this.setState({
              loading: false,
              hasMore: (feed.length >= 19)
            })
          })
      }
    }
  }

  flushTop = () => {
    const { tag, original, hashtag, flushFeeds, isCrossFeed, isFavourite, hashtagTag } = this.props
    const opts = { tag, original, hashtag, isCrossFeed, isFavourite, hashtagTag }
    const id = getFeedId(opts)
    flushFeeds(id)
    this.onScrollTop()
    this.setState({ notificationVisible: false })
  }

  loadTop(firstTs) {
    const { tag, original, hashtag,fulltext, isFavourite, isCrossFeed, isCrossTag, isGeo,feedPollUpdate, hashtagLabel, hashtagTag, isReadListPage, filters = {} } = this.props

    const { category, type, lang } = filters

    let options = {
      geo: isGeo,
      h: false,
      o: 0,
      s: 0,
      filters: {
        category,
        type,
        lang,
        social_types: filters[getFilterNameFromOriginal(original)]
      }
    }

    if (isReadListPage) {

    } else {
      let id = 'World'

      if (isCrossFeed) {
        id = `${tag}-${hashtagTag}`
        if (isCrossTag) {
          if (fulltext) {
            options = {
              ...options,
              o: 0,
              h: false,
              f: true,
              q: hashtagTag,
              r: fulltext,
              t: 0
            }
          } else {
            options = {
              ...options,
              h: true,
              o: 0,
              q: hashtagLabel,
              r: tag
            }
          }
        } else {
          options = {
            ...options,
            o: 0,
            q: hashtagTag,
            r: tag
          }
        }
      } else if (hashtag) {
        options = {
          ...options,
          h: true,
          q: hashtag
        }
        id = hashtag
      } else if (tag) {
        options = {
          ...options,
          q: tag,
          original
        }
        id = `${original}-${tag}`
      } else if (isFavourite) {
        options = {
          ...options,
          original
        }
        id = `favourites-${original}`
      }

      options = {
        ...options,
        t: firstTs
      }

      fetchStream(options, isFavourite, isCrossFeed)
        .then((feeds) => {
          feedPollUpdate(feeds, id)
          const delta = feeds && feeds.length
          if (delta) {
            this.setState({
              notificationVisible: true,
              newsCount: (delta)
            })
          }
        })
    }
  }

  readyToPoll() {
    const { feeds = {}, tag, original, hashtag, isCrossFeed, filters: { to: dateTo }, hashtagTag,isFavourite } = this.props
    const opts = { tag, original, hashtag, isCrossFeed, isFavourite, hashtagTag }
    const id = getFeedId(opts)

    if (
      feeds[id]
      && feeds[id].length > 0
      && moment().diff(dateTo, 'days') === 0
    ) {
      const firstTs = (feeds[id][0])._source.date
      this.loadTop(firstTs)
    }
  }

  loadSnapshot() {
    const { tag, original, hashtag, fulltext, isFavourite, setFeeds, isCrossTag, isCrossFeed, isGeo, hashtagLabel, hashtagTag, filters = {} } = this.props
    const { from, to, category, type, lang } = filters

    let options = {
      geo: isGeo,
      d: { from, to },
      filters: {
        category,
        type,
        lang,
        social_types: filters[getFilterNameFromOriginal(original)]
      }
    }

    let id = 'World'

    this.setState({ loading: true })
    if (isCrossFeed) {
      id = `${tag}-${hashtagTag}`
      if (isCrossTag) {
        if (fulltext) {
          options = {
            ...options,
            o: 0,
            h: false,
            f: true,
            q: hashtagTag,
            r: fulltext,
            t: 0
          }
        } else {
          options = {
            ...options,
            o: 0,
            h: true,
            q: hashtagLabel,
            r: tag,
            t: 0
          }
        }
      } else {
        options = {
          ...options,
          o: 0,
          q: isGeo ? null : hashtagTag,
          r: tag,
          t: 0
        }
      }
    } else if (isFavourite) {
      id = `favourites-${original}`
      options = {
        q: tag,
        ...options,
        original
      }
    } else if (hashtag) {
      id = hashtag
      options = {
        ...options,
        q: hashtag,
        h: true
      }
      id = hashtag
    } else if (fulltext) {
      id = fulltext
      options = {
        ...options,
        q: fulltext,
        f: true
      }
    } else if (tag) {
      id = `${original}-${tag}`
      options = {
        q: tag,
        ...options,
        original
      }
    }

    fetchStream(options, isFavourite, isCrossFeed)
      .then((res) => {
        setFeeds(res, id)
        this.setState({
          loading: false,
          hasMore: (res && res.length >= 19)
        })
      })
  }

  render() {
    const { notificationVisible, loading, newsCount, hasMore } = this.state
    const { feeds = {}, t,  isCrossFeed, isSingleColumn, isReadListPage, isFavourite, hashtagTag,name, hashtag, fulltext, original, tag, language, readListStream, onlyLargeScreens, setSingleColumnOriginal } = this.props

    const opts = { tag, original, hashtag, isCrossFeed, isFavourite, hashtagTag, fulltext }

    let streams = []

    if (isReadListPage) {
      streams = Object.values(readListStream)
    } else {
      const id = getFeedId(opts)
      streams = feeds[id]
    }

    const origin = isCrossFeed ? 3 : original

    return (
      <FeedWrapper onlyLargeScreens={ onlyLargeScreens }>
        <FeedTitle
          isCrossFeed={ isCrossFeed }
          isSingleColumn={ isSingleColumn }
          isFavourite={ isFavourite }
          isReadListPage={ isReadListPage }
          setSingleColumnOriginal={ setSingleColumnOriginal }
          original={ origin }
          name={ name }
          hashtag={ hashtag }
          fulltext={ fulltext } />
        { notificationVisible && (
          <Notification
            addNewStreams={ () => this.flushTop() }
            count={ newsCount } />
        ) }
        <Scroller ref={ this.getScrollWrapper }>
          <InfiniteScroll
            initialLoad={ false }
            pageStart={ 0 }
            loadMore={ () => !loading && this.loadBottom() }
            hasMore={ hasMore }
            useWindow={ false }
            loader={
              !isReadListPage && !_.isEmpty(streams) && (
                <div className="loader stream-row" key={0}>
                  <Spin className="loading" />
                </div>
              )
            }>
            <Posts
              title={ t('livestream_feed') }
              streams={ streams }
              isSingleColumn={ isSingleColumn }
              isReadListPage={ isReadListPage }
              isLoading={ loading }
              language={ language } />
          </InfiniteScroll>
        </Scroller>
      </FeedWrapper>
    )
  }
}

function mapStateToProps(state, ownProps) {
  const { favourites, feeds, filters, readList: { stream: readListStream } } = state
  const { tag, original, hashtag, fulltext, name, isFavourite, isCrossFeed, hashtagTag } = ownProps

  const opts = { tag, original, hashtag, fulltext, isCrossFeed, isFavourite, hashtagTag }
  const { cross } = feeds

  const id = getFeedId(opts) || name

  return {
    favourites,
    filters,
    feeds: {
      [id]: feeds[id],
    },
    cross,
    readListStream
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setReadlist: (feed) => {
      const action = readlistSnapshot({ data: feed })
      dispatch(action)
    },
    setFeeds: (feed, id) => {
      const action = feedSnapshot({ data: feed, id })
      dispatch(action)
    },
    feedPollUpdate: (feed, id) => {
      const action = feedUpdateTop({ data: feed, id })
      dispatch(action)
    },
    feedScrollUpdate: (feed, id) => {
      const action = feedUpdateBottom({ data: feed, id })
      dispatch(action)
    },
    flushFeeds: (id) => {
      const action = flushNews({ id })
      dispatch(action)
    },
    removeNewFeeds: (id) => {
      const action = feedRemoveAllNew({ id })
      dispatch(action)
    },
    removeFeeds: (id) => {
      const action = feedRemoveAll({ id })
      dispatch(action)
    },
    applyUpdate: (data) => {
      const action = applyFiltersUpdate(data)
      dispatch(action)
    }
  }
}
export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(Feed))

const FeedWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  max-width: 800px;
  background-color: #fff;
  border: 1px solid ${ cssvar('borderColor') };
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  ${ (props) => props.onlyLargeScreens && css`
    @media (max-width: 1200px) {
      display: none;
    }
  ` }
  @media (max-width: 800px) {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border: none;
    border-radius: 0;
  }
`
const Scroller = styled.div`
  flex: 1 1 auto;
  overflow-y: scroll;
`