import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelectedRow } from './contexts'
import CoverageStatus from './CoverageStatus'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'

const getDataSource = (data, dataFilters) => data.filter(({
  injuried_part: injuriedPart, side: injuriedSide, other_event,
}) => {
  const isOtherEvent = other_event

  if (dataFilters) {
    const { part, side } = dataFilters

    // If a filter is set avoid to read "void" side in table
    return injuriedPart === part && (injuriedSide === side || injuriedSide === 'both') && !isOtherEvent
  }
  return !isOtherEvent
})

const InjuriesOverall = ({
  data, coverageStatus, coverageProgress, playerId, playerName,
}) => {
  const { t } = useTranslation()
  const { bodyPartSelectedState: [dataFilters] } = useSelectedRow()
  const filteredData = getDataSource(data, dataFilters)

  const sumRecovery = subject => _.sumBy(filteredData, (injury) => {
    if (injury.parent_injury_id) return 0
    if (_.find(filteredData, { parent_injury_id: injury.id })) {
      const subInjuries = _.filter(filteredData, { parent_injury_id: injury.id })
      return _.sumBy(subInjuries, subject) > 0 ? _.sumBy(subInjuries, subject) : injury[subject]
    }
    return injury[subject]
  })

  return (
    <Overall>
      <CoverageStatus
        status={coverageStatus}
        progress={coverageProgress}
        playerId={playerId}
        playerName={playerName} />
      <Item>
        <ItemTitle>{t('Total injuries')}</ItemTitle>
        <ItemValue>{filteredData.length}</ItemValue>
      </Item>
      <Item>
        <ItemTitle>{t('Total recovery days')}</ItemTitle>
        <ItemValue>{sumRecovery('recovery_days')}</ItemValue>
      </Item>
      <Item>
        <ItemTitle>{t('Total missed matches')}</ItemTitle>
        <ItemValue>{sumRecovery('missed_matches')}</ItemValue>
      </Item>
    </Overall>
  )
}

export default InjuriesOverall

const Overall = styled.div`
  flex: 1 1 auto;
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
  gap: 30px;
  @media (max-width: 1200px) {
    gap: 20px;
  }
  @media (max-width: 640px) {
    display: none;
  }
`
export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  @media (max-width: 640px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`
export const ItemTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  @media (max-width: 1200px) {
    font-size: 14px;
  }
  @media (max-width: 640px) {
    display: none;
  }
`
export const ItemValue = styled.div`
  color: ${cssvar('injuryColor')};
  font-size: 18px;
  font-weight: 550;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`
