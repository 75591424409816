import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { Container } from '../styledComponents'
import _ from 'lodash'
import { BASE_V2_URL } from '../../../api'
import BarChart from '../../Widgets/BarChart'
import MobileTimeframeSelector from '../../Generic/MobileTimeframeSelector'
import styled from 'styled-components'
import { cssvar } from '../../../styles/var'
import PieChart from '../../Widgets/PieChart'
import moment from 'moment'
import { ActiveTimeFilterOption } from '../../../redux/ducks/filters'


const CompetitionInjuries = ({ data, filters }) => {
  const { t } = useTranslation()
  const [competitionPayload, setCompetitionPayload] = useState({})

  const {
    tag, competition, teams: teamsMap, players: playersMap,
  } = competitionPayload

  const teams = useMemo(() => {
    if (!teamsMap) return []
    return Object.values(teamsMap)
  }, [teamsMap])

  const injuries = useMemo(() => {
    if (!playersMap) return []

    const players = Object.values(playersMap)

    return players.flatMap(p => p.opened_injuries)
  }, [playersMap])

  const fetchData = useCallback(
    _.debounce((filters) => {
      async function fetch(filters) {
        try {
          const timeframe = filters.activeTimeFilter === ActiveTimeFilterOption.timeframe
            ? filters.timeframe
            : `${moment(filters.from).format('YYYY-MM-DD')},${moment(filters.to).format('YYYY-MM-DD')}`
          const result = await axios(
            `${BASE_V2_URL}/competitions/${data.tag}?timeframe=${timeframe}`,
          )
          setCompetitionPayload(result.data)
        } catch (err) {
          console.log('Unable to load data for', data.tag)
        }
      }
      fetch(filters)
    }, 300), [],
  )

  useEffect(() => {
    fetchData(filters)
  }, [filters])


  const allTeamsInjuriesData = useMemo(() => (teams ? teams.filter(team => !!team.team).map((team) => {
    const stats = team.stats
    const {
      injuries: {
        total,
        severe,
        injuries_in_match,
        muscular_injuries_in_match,
      }, recovery_days,
    } = stats

    return {
      name: team.team.meta.common_name,
      link: team.team.tag,
      Injuries: total,
      SignificantInjuries: severe,
      MinorInjuries: total - severe,
      DaysInjured: recovery_days,
      MatchInjuriesPercent: Math.round(injuries_in_match / total * 100),
      MatchMuscularInjuriesPercent: Math.round(muscular_injuries_in_match / total * 100),
      MatchInjuriesAbsolute: team.stats.injuries.injuries_in_match,
      MatchMuscularInjuriesAbsolute: team.stats.injuries.muscular_injuries_in_match,
    }
  }) : []), [teams])

  const injuriesTypology = _.reverse(
    _.sortBy(
      _.map(_.groupBy(injuries, 'injury_type'), inj => ({
        name: t(`injury-type-${inj[0].injury_type}`),
        value: inj.length,
      })),
      'value',
    ),
  )
  const InjuriesTypesWidget = injuriesTypology.length > 3 ? BarChart : PieChart

  const whereTypology = _.map(
    _.groupBy(_.reject(injuries, { where: 'none' }), 'where'),
    inj => ({
      name: t(`injury-match-where-${inj[0].where}`),
      value: inj.length,
    }),
  )

  const traumaTypology = _.map(
    _.groupBy(
      _.reject(injuries, i => i.trauma === 'na' || !!i.other_event || !i.trauma),
      'trauma',
    ),
    inj => ({ name: t(`injury-contact-${inj[0].trauma}`), value: inj.length }),
  )

  const severityTypology = _.chain(injuries)
    .reject({ recovery_days: null })
    .groupBy((inj) => {
      if (inj.recovery_days < 30) return '< 30 days'
      if (inj.recovery_days >= 30 && inj.recovery_days <= 60) return '30-60 days'
      if (inj.recovery_days > 60) return '> 60 days'
    })
    .sortBy(inj => inj[0].recovery_days)
    .map(inj => ({
      name: t(
        `${inj[0].recovery_days >= 30 && inj[0].recovery_days <= 60
          ? '30-60'
          : inj[0].recovery_days < 30
            ? '< 30'
            : '> 60'
        } days`,
      ),
      value: inj.length,
    }))
    .value()

  if (!competition) return null

  return (
    <Container>
      <Widgets>
        <MobileTimeframeSelector />
        <Widget>
          <WidgetTitle>
            {t('Injuries')}
            <TotalValue>
              {_.sumBy(allTeamsInjuriesData, 'Injuries')}
              <span>{` / ${_.sumBy(allTeamsInjuriesData, 'SignificantInjuries')}`}</span>
            </TotalValue>
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, ['Injuries', 'SignificantInjuries'])}
            dataKey="Injuries"
            dataKey2="SignificantInjuries"
            nameKey="name"
            linkKey="link" />
        </Widget>
        <Widget>
          <WidgetTitle>
            {t('Days injured')}
            <TotalValue>
              {_.sumBy(allTeamsInjuriesData, 'DaysInjured')}
            </TotalValue>
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, 'DaysInjured')}
            dataKey="DaysInjured"
            nameKey="name"
            linkKey="link" />
        </Widget>
        <Widget>
          <WidgetTitle>
            {t('Injuries at matchday')}
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, 'MatchInjuriesPercent')}
            dataKey="MatchInjuriesPercent"
            absoluteValueKey="MatchInjuriesAbsolute"
            nameKey="name"
            linkKey="link"
            percents />
        </Widget>
        <Widget>
          <WidgetTitle>
            {t('Muscular injuries at matchday')}
          </WidgetTitle>
          <BarChart
            data={_.sortBy(allTeamsInjuriesData, 'MatchMuscularInjuriesPercent')}
            dataKey="MatchMuscularInjuriesPercent"
            absoluteValueKey="MatchMuscularInjuriesAbsolute"
            nameKey="name"
            linkKey="link"
            percents />
        </Widget>
        {injuriesTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Injury types')}</WidgetTitle>
            <InjuriesTypesWidget data={injuriesTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}
        {traumaTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('injury-contact')}</WidgetTitle>
            <PieChart data={traumaTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {whereTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Match/Training')}</WidgetTitle>
            <PieChart data={whereTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}

        {severityTypology.length > 0 && (
          <Widget>
            <WidgetTitle>{t('Severity')}</WidgetTitle>
            <PieChart data={severityTypology} dataKey='value' nameKey='name' />
          </Widget>
        )}
      </Widgets>
    </Container>
  )
}

function mapStateToProps({ filters }) {
  return { filters }
}

export default connect(mapStateToProps)(CompetitionInjuries)

const Widgets = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2em;
  @media(max-width: 1024px) {
    grid-template-columns: 1fr;
    grid-gap: 1em;
    margin-top: 1em;
  }
`
const Widget = styled.div`
  min-width: 0;
  min-height: 0;
  padding: 1em;
  background-color: #fff;
`
const WidgetTitle = styled.h4`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  font-size: 1.5em;
  font-weight: 500;
`
const TotalValue = styled.div`
  font-weight: 600;
  font-size: 16px;
  color: ${cssvar('primaryColor')};
  white-space: nowrap;
  span {
    color: ${cssvar('injuryColor')};
  }
`