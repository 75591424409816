import React from 'react'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import _ from 'lodash'
import { Select } from 'antd'
import { connect } from 'react-redux'
import { ActiveTimeFilterOption, setTimeframe } from '../../redux/ducks/filters'

class TimeframeSelectorComponent extends React.Component {
  currentUrl = urls => _.some(urls, url => _.includes(this.props.location.pathname, url))

  updateTimeframe = () => {
    if (this.options.length === 0) return
    if (this.props.filters.activeTimeFilter === ActiveTimeFilterOption.range) {
      return
    }
    return this.props.setTimeframe(this.options[0].value)
  }

  componentDidMount() {
    this.updateTimeframe()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.filters.timeframe === 'year' && _.some(['competition_injuries', 'roster'], url => _.includes(this.props.location.pathname, url))) {
      this.updateTimeframe()
    }
    if (this.props.filters.activeTimeFilter === ActiveTimeFilterOption.range) {
      this.props.setTimeframe(undefined)
    }
  }

  componentWillUnmount() {
    this.updateTimeframe()
  }

  get options() {
    return [
      ...(
        !this.currentUrl(['competition_injuries', 'roster']) ? [
          { label: this.props.t('Last 12 months'), value: 'year' },
        ] : []
      ),
      ...(
        this.currentUrl(['team_injuries']) ? [
          { label: this.props.t('Last 6 months'), value: 'last_6_months' },
          { label: this.props.t('Last 3 months'), value: 'last_3_months' },
        ] : []
      ),
      ...((this.props.navDrilldownHeader.data.seasons && this.props.navDrilldownHeader.data.seasons.slice(0, 4)) || []),
    ]
  }

  render() {
    const { filters, setTimeframe } = this.props
    return (
      <Select
        key={`timeframe-selecor-${filters.timeframe}`}
        defaultValue={filters.activeTimeFilter === ActiveTimeFilterOption.timeframe ? filters.timeframe : undefined}
        style={{ width: 200 }}
        onChange={setTimeframe}
        placeholder={this.props.t('select_season')}
      >
        {_.map(this.options, option => <Select.Option value={option.value}>{option.label}</Select.Option>)}
      </Select>
    )
  }
}

const mapStateToProps = ({ filters, navDrilldownHeader }) => ({
  filters,
  navDrilldownHeader,
})

function mapDispatchToProps(dispatch) {
  return {
    setTimeframe: (value) => {
      const action = setTimeframe(value)
      dispatch(action)
    },
  }
}

export default withTranslation('translation')(connect(mapStateToProps, mapDispatchToProps)(withRouter(TimeframeSelectorComponent)))